import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalData } from '../GlobalData';
import { FaChevronDown } from 'react-icons/fa';

const Selected = styled.div`
  background: ${props => props.disabled ? '#f9f9f9' : '#ffffff'};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  border-bottom-left-radius: ${props => props.$optionsVisible ? "0px" : "5px"};
  border-bottom-right-radius: ${props => props.$optionsVisible ? "0px" : "5px"};
  box-shadow: ${props => props.$optionsVisible ? "0px 0px 3px 2px rgba(68, 172, 225, 0.7)" : "none"};
  padding: 10px 20px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  width: ${props => props.$width};
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  color: #080808;
  font-size: 14px;
`;

const Menu = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  position: absolute;
  width: ${props => props.$width};
  box-shadow: 0px 2px 4px 2px rgba(68, 172, 225, 0.7);
  top: 0px;
  left: 0px;
  margin: 0px;
  z-index: 999;
  display: ${props => props.$optionsVisible ? "block" : "none"};
  box-sizing: border-box;
`;

const Option = styled.div`
  font-size: 14px;
  padding: 8px 20px;
  cursor: pointer;
  background: ${props => props.$selected ? "#44ACE1" : "#ffffff"};
  color: ${props => props.$selected ? "#ffffff" : "#080808"};
  text-align: left;
  border-bottom: ${props => props.$hasBorderBottom ? '1px solid #dedede' : 'none'};
  /*
   * Did not like how this performed, but if desired the below css can reference the parent hover
   * and override the selected menu items colors with the base colors
  ${Menu}:hover & {
    background: #ffffff;
    color: #080808;
  }*/
  &:hover {
    background: #44ACE1!important;
    color: #ffffff!important;
  }
`;

export default ({
  options = [],
  value: defaultValueProp,
  onChange,
  width: widthProp,
  disabled
}) => {

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const width = isMobileView ? '100%' : (widthProp ? widthProp : '500px');

  const defaultValue = defaultValueProp || (options[0] || {}).value;

  const [value, setValue] = useState(defaultValue)
  
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const displayValue = (options.find(({ value: optionValue }) => optionValue === value) || {}).text;

  const [optionsVisible, setOptionsVisible] = useState();

  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    const offClickEvent = () => {
      setOptionsVisible(false)
    }
    document.addEventListener('click', offClickEvent)

    return () => {
      document.removeEventListener('click', offClickEvent)
    }
  }, [])

  return (
    <div>
      <Selected
        $width={width}
        $optionsVisible={optionsVisible}
        onClick={(e) => {
          if(!disabled) {
            e.stopPropagation();
            setOptionsVisible(!optionsVisible)
          }
        }}
        disabled={disabled}
      >
        <div style={{ flex: 1 }}>{displayValue}</div>
        <FaChevronDown style={{ marginLeft: '5px', marginTop: '3px', color: '#44ACE1' }} />
      </Selected>
      <div style={{
        position: 'relative',
      }}>
      <Menu $width={width} $optionsVisible={optionsVisible}>
      {
        options.map(({ value: optionValue, text, hasBorderBottom }) => {
          return (
            <Option 
              key={optionValue} 
              $selected={optionValue === value} 
              $hasBorderBottom={hasBorderBottom}
              onClick={() => setValue(optionValue)}
            >
              {text}
            </Option>
          )
        })
      }
      </Menu>
      </div>
    </div>
  )
}