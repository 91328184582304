import React from 'react';
import KeyValueIconBlock from '../../CommonComponents/KeyValueIconBlock';
import moment from 'moment';
import { addPlural } from '../../utils';
import { 
  FaRegCalendar, 
  FaLayerGroup, 
  FaMicrophone, 
  FaUserFriends,
  FaUserGraduate,
  FaBookOpen,
  FaBook,
  FaHandHolding,
  FaRegCheckCircle,
  FaRegSmile
} from 'react-icons/fa';
import { Section, SectionLabel } from '../../CommonComponents/BaseComponents';
import orangeBackground from '../../images/background-orange.png'
import { useGlobalData } from '../../GlobalData';

export default ({
  programData = {}
}) => {
  const {
    type = '',
    programStart,
    programEnd,
    sessionCount,
    numTrainers,
    numAttendees,
    numStudents,
    expectedAttendees,
    activatedLicenses,
    completedLicenses,
    studentRetention,
    percentOfEngagementLearningAchieved,
    percentOfSELLearningAchieved,
    percentOfWorkforceLearningAchieved,
    endUserPrimarySatisfaction,
    endUserPrimarySatisfactionLabel,
    studentPrimarySatisfaction,
    studentPrimarySatisfactionLabel
  } = programData;

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const numDays = moment(programEnd).diff(moment(programStart), 'days');
  const attendees = numAttendees || expectedAttendees;

  const completedLicensesPercentage = Math.floor((completedLicenses/activatedLicenses) * 100);

  const completedLicensesValue = completedLicenses ? `${completedLicenses} (${completedLicensesPercentage}%)` : '';

  const isEventType = type.indexOf('PROGRAM') > -1;

  const tooltips = {
    // 'Day': 'Day',
    // 'Attendee': 'This is the number of attendess we had attent the program.'
  }

  const primaryFacilitatorSatisfactionLabel = endUserPrimarySatisfactionLabel || 'End User Primary Satisfaction';
  const primaryStudentSatisfactionLabel = studentPrimarySatisfactionLabel || 'Student Primary Satisfaction';

  const dataPoints = [

    { key: 'Day', value: numDays, icon: <FaRegCalendar />, row: 1 },    // Group 1 (Event type)
    { key: 'Session', value: sessionCount, icon: <FaLayerGroup />, row: 1 }, // Group 1  (Event type)
    { key: 'Trainer', value: numTrainers, icon: <FaMicrophone />, row: 1 }, // Group 1  (Event type)
    { key: isEventType ? 'Attendee' : 'Assigned Licenses', value: attendees, icon: <FaUserFriends />, row: isEventType ? 1 : 2 }, // Group 1 (Event Type), Group 2  (Subscription Type)
    { key: 'Activated License', value: activatedLicenses, icon: <FaBookOpen />, row: 2 }, // Group 2 (Subscription Type)
    { key: 'Completed License', value: completedLicensesValue, pluralCheckVal: completedLicenses, icon: <FaBook />, row: 2 }, // Group 2 (Subscription Type)
    { key: 'Student', value: numStudents, icon: <FaUserGraduate />, row: 3 }, // Group 3 (All types)
    { key: 'Student Retention', value: Boolean(studentRetention) && `${studentRetention}%`, icon: <FaHandHolding />, row: 3 }, // Group 3 (All types)
    { key: 'Engagement Learning Achieved', value: Boolean(percentOfEngagementLearningAchieved) && `${Math.floor(percentOfEngagementLearningAchieved)}%`, icon: <FaRegCheckCircle />, row: 3 },
    { key: 'SEL Learning Achieved', value: Boolean(percentOfSELLearningAchieved) && `${Math.floor(percentOfSELLearningAchieved)}%`, icon: <FaRegCheckCircle />, row: 3 },
    { key: 'Workforce Learning Achieved', value: Boolean(percentOfWorkforceLearningAchieved) && `${Math.floor(percentOfWorkforceLearningAchieved)}%`, icon: <FaRegCheckCircle />, row: 3 },
    { key: primaryFacilitatorSatisfactionLabel, value: Boolean(endUserPrimarySatisfaction) && `${endUserPrimarySatisfaction}%`, icon: <FaRegSmile />, row : 3 },
    { key: primaryStudentSatisfactionLabel, value: Boolean(studentPrimarySatisfaction) && `${studentPrimarySatisfaction}%`, icon: <FaRegSmile />, row : 3 }

  ]

  const eventSummaryData = [];
  dataPoints.forEach(({ key, value, icon, pluralCheckVal }) => {
    if (value) {
      eventSummaryData.push({ key: `${key}${addPlural(pluralCheckVal || value)}`, value, icon, tooltip: tooltips[key] })
    }
  });

  // const eventSummaryDataRows = [];


  // if (averageReviewStars) {
  //   eventSummaryDataRows[0] = [{ title: averageReviewStars, isAverageRatingBlock: true }];
  // }
  // dataPoints.forEach(({ key, value, icon, pluralCheckVal, row }) => {
  //   if (value) {
  //     if (!eventSummaryDataRows[row]) {
  //       eventSummaryDataRows[row] = [];
  //     }
  //     const eventSummaryData = eventSummaryDataRows[row];
  //     eventSummaryData.push({ title: `${value} ${key}${addPlural(pluralCheckVal || value)}`, icon })
  //   }
  // });

  // let blockCounter = 0;

  return (
    <Section style={{ padding: '20px 0px' }}>
      <SectionLabel style={{ padding: '0px 20px' }}>Highlights by the Numbers</SectionLabel>
      <div style={{
        background: `url(${orangeBackground}) top left/cover no-repeat`,
        marginTop: '20px',
        display: 'flex',
        flexDirection: isMobileView ? 'column' : 'row',
        flexWrap: 'wrap',
        gap: '20px 20px',
        justifyContent: 'center',
        padding: '15px 20px',
        cursor: 'default'
      }}>
        {
          eventSummaryData.map(({ key, value, icon, tooltip }, index) => {
            const animationDelay = (index + 1) * 250;
            return (
              <div key={key}>
                <KeyValueIconBlock
                  valueKey={key}
                  value={value}
                  icon={icon}
                  animationDelay={animationDelay}
                  tooltip={tooltip}
                />
              </div>
            )
          })
        }
      {/* 
      {
        --- Original Multi row logic, keeping for reference ---
        eventSummaryDataRows.map((eventSummaryData, rowIndex) => {
          return (
            <div
              key={rowIndex}
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: isMobileView ? 'column' : 'row',
                flexWrap: 'wrap',
                gap: '20px 20px',
                justifyContent: 'center'
              }}
            >
              {
                eventSummaryData.map(({ title, icon, isAverageRatingBlock }) => {
                  const animationDelay = blockCounter * 250;
                  blockCounter++;
                  return (
                    <div key={title}>
                      {
                        isAverageRatingBlock 
                        ?
                        <AverageRatingBlock stars={title} animationDelay={animationDelay} />
                        :
                        <SummaryBlock
                          title={title}
                          icon={icon}
                          animationDelay={animationDelay}
                        />
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      } 
      */}
      </div>
    </Section>
  )
}
