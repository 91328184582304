import React, { useRef, useEffect, useState } from 'react';
import useDetectElementVisibility from '../../hooks/useDetectElementVisibility';

export default (props) => {
  const domRef = useRef();
  const { isVisible, visibilityOccured } = useDetectElementVisibility(domRef);

  const { animationDelay } = props;
  const [timerPassed, setTimerPassed] = useState(animationDelay ? false : true)
  useEffect(() => {
    if (animationDelay && isVisible) {
      setTimeout(() => {
        setTimerPassed(true)
      }, animationDelay)
    }
  }, [isVisible, animationDelay])

  const style = (timerPassed && (visibilityOccured || isVisible)) ? 
  {
    opacity: '1',
    visibility: 'visible',
    animation: 'scaleIn 2s ease-out 1',
    borderRadius: '5px',
  }
  :
  {
    opacity: '0',
    visibility: 'hidden'
  }

  return (
    <div ref={domRef} style={style}>
      {props.children}
    </div>
  );
}