import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
// Reminder Left Nav component below is really just used on the top nav here now due to code restructure
import AssessmentManagerLeftNav from './AssessmentManagerLeftNav';
import LogoutButton from '../CommonComponents/LogoutButton'; 

export default () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onHomeClicked = () => {
    if (pathname !== '/assessmentManager') {
      navigate('/assessmentManager');
    }
  }

  return (
    <div style={{
      display: 'flex',
      height: '50px',
      padding: '0px 20px',
      alignItems: 'center',
      background: '#ffffff',
      borderBottom: '1px solid #C2C9D1',
    }}>
      <div 
        style={{
          color: '#565656',
          fontSize: '24px',
          fontWeight: 'bold',
          cursor: 'pointer',
          marginRight: '20px'
        }}
        onClick={onHomeClicked}
      >
        {'Assessment Manager'}
      </div>
      <AssessmentManagerLeftNav />
      <div style={{ flex: 1 }}>&nbsp;</div>
      <LogoutButton />
    </div>
  )
}