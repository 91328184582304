import React from "react";
import LoadingIcon from "./Animations/LoadingIcon";

export default () => {
  return (
    <div style={{ 
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      background: '#b8b8b877',
      zIndex: '100001'
    }}>
      <div style={{ 
        padding: '5px 5px',
        height: 'calc(100vh - 10px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <LoadingIcon />
      </div>
    </div>
  )
}