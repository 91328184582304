import React, { useRef } from 'react';
import useDetectElementVisibility from '../../hooks/useDetectElementVisibility';

export default (props) => {
  const domRef = useRef();
  const { isVisible, visibilityOccured } = useDetectElementVisibility(domRef);

  const baseStyle = {
    transition: 'opacity 1s ease-out, transform 1s ease-out',
    willChange: 'opacity, visibility'
  }

  const style = (visibilityOccured || isVisible) ? 
  {
    ...baseStyle,
    opacity: '1',
    transform: 'none',
    visibility: 'visible',
  }
  :
  {
    ...baseStyle,
    opacity: '0',
    transform: 'translateY(0vh)',
    visibility: 'hidden',
  }

  return (
    <div ref={domRef} style={style}>
      {props.children}
    </div>
  );
}