import React, { useState, useEffect, useRef } from 'react';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';

const StarWrapper = styled.span`
  margin-right: 8px;
  cursor: pointer;
  color:  ${props => props.$selected ? ( props.$selectedStarColor || '#fa9201' ) : '#b1b5c4' };
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:hover {
    color: ${props => props.$selected ? ( props.$selectedStarColor ||  '#b36d0c' ) : '#6c7081' };
    opacity: 0.9;
  }
  &:active{
    color: ${props => props.$selectedStarColor ||  '#df8b17'};
    opacity: 0.8;
  }
`;

export default ({
  initStarValue,
  selectedStarColor,
  totalStars = 10,
  starLabels,
  onStarsChanged,
}) => {
  const [stars, setStars] = useState(initStarValue || 0)

  const [hoveredStar, setHoveredStar] = useState(-1)

  const timeoutRef = useRef();
  const onHoveredStar = (val) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setHoveredStar(val)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const onUnhoveredStar = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredStar(-1)
    }, 150)
  }

  useEffect(() => {
    if (onStarsChanged) {
      onStarsChanged(stars)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stars])

  const starsArray = [];
  for (let i = 0; i < totalStars; i++) {

    let opacity;
    // If we only have 2 labels...assume its on the ends and always set opacity to 1
    if (starLabels && Object.keys(starLabels).length === 2) {
      opacity = '1';
    } else if (hoveredStar === -1 && (i === 0 || i === (totalStars - 1))) {
      opacity = '1';
    } else if (hoveredStar === i) {
      opacity = '1';
    } else {
      opacity = '0';
    }

    const selected = (i < stars);
    starsArray.push(
      <StarWrapper 
        key={`star${i}`} 
        $selected={selected}
        $selectedStarColor={selectedStarColor}
        onClick={() => setStars((i + 1))}
        onMouseEnter={() => onHoveredStar(i)}
        onMouseLeave={() => onUnhoveredStar()}
      >
        <FaStar style={{ fontSize: '42px' }} />
        {
          starLabels && starLabels[(i+1)] &&
          <div
            style={{ 
              position: 'absolute', 
              bottom: '-30px', 
              whiteSpace: 'nowrap',
              color: selected ? (selectedStarColor || '#fa9201') : '#b1b5c4',
              fontSize: '18px',
              opacity
            }}>
              {starLabels[(i+1)]}
            </div>
        }
      </StarWrapper>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {starsArray}
    </div>
  )
}
