import React from "react";
import ProsolveLogo from '../images/prosolve-logo.png';

export default ({
  style = {},
  hideTagLine
}) => {
  return (
    <div style={{
      background: '#979797',
      color: '#ffffff',
      borderRadius: '5px',
      // borderBottomLeftRadius: '5px',
      // borderBottomRightRadius: '5px',
      padding: '30px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ...style
    }}>
      {/* <div style={{ margin: '10px 0px', fontWeight: 'bold', fontSize: '18px' }}>
        We can't wait to see what's in store for you next
      </div> */}
      { !hideTagLine &&
        <div style={{ margin: '10px 0px', fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
          WE CAN'T WAIT TO SEE WHAT'S IN STORE FOR YOU NEXT
        </div>
      }
      <img src={ProsolveLogo} style={{ width: '200px' }} alt="prosolveLogo"/>
    </div>
  )
}