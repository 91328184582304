import React, { useState } from "react";
import Modal from "../CommonComponents/Modal";
import { Button } from '../CommonComponents/BaseComponents'
import FormField from "../CommonComponents/FormField";
import useForm from '../hooks/useForm';
import useFetch from '../hooks/useFetch';

export default ({
  fetchQuestCodes,
  downloadQuestCodes,
  onClose,
}) => {

  const formSchema = {
    requiredFields: ['numCodes', 'productType'],
    initData: {
      numCodes: '',
      productType: ''
    }
  }

  const formData = useForm(formSchema)

  const [isBusy, setIsBusy] = useState(false);
  const [successData, setSuccessData] = useState()
  const [error, setError] = useState()

  const fetch = useFetch();

  const onClickSubmit = async () => {
    // For now just do min / max number check on value
    if (formData.isValid() && formData.values.numCodes > 0) {

      setIsBusy(true)

      const response = await fetch(`quest/codes/provision`, 
      { 
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData.values)
      })

      const jsonData = await response.json();

      if (jsonData.success) {
        setError(false)
        setSuccessData(jsonData)

        fetchQuestCodes();

        downloadQuestCodes(jsonData.productType, jsonData.provisionGroupNumber)

      } else {
        setError(true)
      }

      setIsBusy(false)
    }
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) ?
        <div>{'Provisioning...'}</div> :
        Boolean(error) ?
        <div style={{
          color: '#ef0c0c',
          fontStyle: 'italic',
          fontSize: '14px',
        }}>
          {'Error occurred provisioning codes'}
        </div>
        :
        <div>&nbsp;</div>
      }
      <Button $disabled={isBusy} onClick={() => { !isBusy && onClose()}} >
        {successData ? 'Close' : 'Cancel'}
      </Button>
      { !successData &&
        <Button $colored={true} $disabled={isBusy} onClick={() => { !isBusy && onClickSubmit()}} >
          {'Submit'}
        </Button>
      }
    </div>
  )

  const productTypeOptions = 
    ['Adventures Apex', 'Adventures Starforce', 'Creators Intermediate', 'Creators Primary', 'Internal Test Codes']
    .map(val => ({ text: val, value: val }))

  return (
    <Modal title={'Provision New Quest Codes'} onClose={() => { !isBusy && onClose()}} footer={footer}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}>
        {
          successData ?
          <>
            <div style={{ fontWeight: 'bold', fontSize: '48px', textAlign: 'center', color: '#2a932a', marginBottom: '20px'}}>Success!</div>
            <div style={{ fontWeight: 'bold', fontSize: '24px', textAlign: 'center', color: '#F88700'}}>{`Product Type: ${successData.productType}`}</div>
            <div style={{ fontWeight: 'bold', fontSize: '24px', textAlign: 'center', color: '#0280c6'}}>{`Provisioned To Group Number: ${successData.provisionGroupNumber}`}</div>
            <div style={{ textAlign: 'center',  marginTop: '20px' }}>{'Click button below to download csv file of newly provisioned codes'}</div>
            <Button 
              style={{ alignSelf: 'center', width: '250px' }} 
              $colored={true} 
              $disabled={isBusy} 
              onClick={() => downloadQuestCodes(successData.productType, successData.provisionGroupNumber)} 
            >
              {'Download'}
            </Button>
          </>
          :
          <>
          <div style={{ marginBottom: '20px'}}>Select how many new codes you wish to provision:</div>
          <FormField
            style={{ flex: 1 }}
            fieldId='numCodes'
            type='number'
            label='Number of New Codes'
            formData={formData}
          />
          <FormField
            fieldId='productType'
            type='select'
            label='Product Type'
            width='100%'
            options={productTypeOptions}
            formData={formData}
          />
        </>
        }
      </div>
    </Modal>
  )
}