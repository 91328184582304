import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useGlobalData } from '../GlobalData';

const Input = styled.input`
  background: ${props => props.disabled ? '#f9f9f9' : '#ffffff'};
  border: ${props => props.$isInvalid ? '1px solid #ef0c0c' : '1px solid #c4c4c4'};
  border-radius: 5px;
  padding: 10px 20px;
  width: ${props => props.$width};
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  color: #080808;
  font-size: 14px;
  outline: 0;
  &:focus {
    border: 1px solid rgba(68, 172, 225, 0.7);
    box-shadow: 0px 0px 3px 2px rgba(68, 172, 225, 0.7);
  }
`;

export default forwardRef(({
  style: styleProp,
  value: defaultValueProp,
  type,
  min,
  onChange,
  onClick = () => {},
  autoFocus,
  onKeyDown: onKeyDownProp,
  width: widthProp,
  disabled,
  placeholder = '',
  isInvalid
}, ref) => {

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const width = isMobileView ? '100%' : (widthProp ? widthProp : 'auto');

  const defaultValue = defaultValueProp || '';

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useImperativeHandle(ref, () => {
    return {
      clearInput() {
        setValue('')
      }
    };
  }, []);

  const style = styleProp ? styleProp : {}

  return (
      <Input
        style={style}
        type={type || 'text'}
        min={min}
        $width={width}
        $isInvalid={isInvalid}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if(onKeyDownProp) {
            onKeyDownProp(e)
          }
        }}
        placeholder={placeholder}
        disabled={disabled}
        onClick={onClick}
        autoFocus={autoFocus}
        data-1p-ignore
      >
      </Input>
  )
})