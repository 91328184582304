import React from "react";
import Modal from "../../../../CommonComponents/Modal";
import { Button } from '../../../../CommonComponents/BaseComponents'
import useForm from '../../../../hooks/useForm';
import FormField from "../../../../CommonComponents/FormField";

export default ({
  initCategoryName,
  onClose,
  createNewCategory,
  existingCategories = []
}) => {

  const isNew = initCategoryName === 'new';

  const initData = isNew ? {} : { category: initCategoryName }

  const formSchema = {
    requiredFields: ['category'],
    initData
  }

  const formData = useForm(formSchema)
  
  const category = formData.values.category;

  const catConflict = existingCategories.find(cat => cat.toLowerCase() === category.toLowerCase());

  const onClickSubmit = () => {
    if (formData.isValid() && !catConflict) {
      createNewCategory({ initCategoryName, isNew, category })
    }
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      padding: '10px'
    }}>
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true}>
        {'Submit'}
      </Button>
    </div>
  )

  return (
    <Modal title={isNew ? 'Create Category' : 'Rename Category'} onClose={onClose} footer={footer}>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='category'
          type='text'
          formData={formData}
        />
        {
          catConflict &&
          <div style={{
            marginTop: '10px',
            color: '#ef0c0c',
            fontStyle: 'italic',
            fontSize: '11px',
            marginLeft: '5px'
          }}>
            {'*Category name must be unique'}
          </div>
        }
      </div>
    </Modal>
  )
}