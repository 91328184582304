import React from 'react';
import ScaleInWrapper from './Animations/ScaleInWrapper';
import TooltipWrapper from './TooltipWrapper';
import styled from 'styled-components';
import { useGlobalData } from '../GlobalData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* background: #f8f9fc;
  border: 1px solid #c4c4c4;
  border-radius: 5px; */
  width: ${props => props.$isMobileView ? "auto" : "150px"};
  flex: ${props => props.$isMobileView ? "1" : "none"};
  height: 70px;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  color: #2c2c2c;
  /* &:hover {
    color: #0280c6; 
    color: #000000;
  } */
`;

export default ({
  value,
  valueKey,
  icon,
  animationDelay,
  tooltip
}) => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  // const [isHovered, setIsHovered] = useState(false)

  return (
    <ScaleInWrapper animationDelay={animationDelay}>
      <TooltipWrapper text={tooltip}>
        <Wrapper $isMobileView={isMobileView} 
          // onMouseEnter={() => setIsHovered(true)} 
          // onMouseLeave={() => setIsHovered(false)}
        >
          {
            icon && 
            <div style={{ 
              // color: '#6e6e6e', 
              fontSize: '22px', 
              marginBottom: '5px' 
            }}>
              {icon}
            </div>
          }
          <div style={{
            marginBottom: '5px',
            fontWeight: 'bold',
            fontSize: '24px', // isHovered ? '34px' : '24px',
            // color: '#565656',
            textAlign: 'center'
          }}>
            {value}
          </div>
          <div style={{
            // marginBottom: '10px',
            fontWeight: 'bold',
            fontSize: '14px',
            // color: '#565656',
            textAlign: 'center'
          }}>
            {valueKey}
          </div>
        </Wrapper>
      </TooltipWrapper>
    </ScaleInWrapper>
  )
}
