import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

export default ({
  onClick = () => {}
}) => {
  return (
    <div
      onClick={onClick}
      style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px'
    }}>
      <div style={{
        height: '60px',
        width: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '22px',
        background: '#44ACE1',
        color: '#ffffff',
        border: '2px solid #ffffff',
        borderRadius: '50%',
        cursor: 'pointer'
      }}>
        <FaChevronDown />
      </div>
    </div>
  )
}