import React, { useState, useEffect, useRef } from "react";
import { useGlobalData } from '../../GlobalData'
import { TabButton } from '../../CommonComponents/BaseComponents'
import TextInput from "../../CommonComponents/TextInput";
import SelectDropdown from '../../CommonComponents/SelectDropdown'
import { Button } from '../../CommonComponents/BaseComponents'

export default ({
  filters,
  setFilters,
  provisionGroupNumberSet = [],
  downloadQuestCodes,
  onProvisonNewCodes
}) => {

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const { productType = '' } = filters;

  const setSelectedProductType = (updateProductType) => {
    setFilters({
      ...filters,
      productType: updateProductType
    })
  }

  const setProvisionGroupNumber = (updateProvisionGroupNumber) => {
    setFilters({
      ...filters,
      provisionGroupNumber: updateProvisionGroupNumber
    })
  }

  const setSpecificCode = (updateSpecificCode) => {
    setFilters({
      ...filters,
      specificCode: updateSpecificCode
    })
  }

  const [searchValue, setSearchValue] = useState('')

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setSpecificCode(searchValue)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const tabs = ['Adventures Apex', 'Adventures Starforce', 'Creators Intermediate', 'Creators Primary', 'Internal Test Codes', 'Unprovisioned Codes']

  const provisonGroupOptions = [
    { text: 'All', value: '' },
    ...(provisionGroupNumberSet.map(val => ({ text: val, value: val })))
  ]

  const labelStyle = { 
    marginRight: '15px',
    width: '130px'
  };

  const canDownload = filters.productType && filters.productType !== 'Unprovisioned Codes' && filters.provisionGroupNumber && !filters.specificCode;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px',
      flex: 1,
      marginTop: '20px'
    }}>
      <div style={{ 
        display: 'flex',
        flexDirection: isMobileView ? 'column' : 'row',
        rowGap: isMobileView ? '15px' : '0px',
        columnGap: isMobileView ? '0px' : '15px',
        paddingBottom: '10px',
        // borderBottom:  '1px solid #dedede',
        // alignItems: 'center'
      }}>
        <div style={{ ...labelStyle, marginRight: '0px', marginTop: '9px' }}>Product Type:</div>
        <div style={{
          display: 'flex',
          flexDirection: isMobileView ? 'column' : 'row',
          rowGap: '15px',
          columnGap: '15px',
          flexWrap: 'wrap'
          // rowGap: isMobileView ? '15px' : '0px',
          // columnGap: isMobileView ? '0px' : '15px',
        }}>
        {
          tabs.map(tab => {
            return (
              <TabButton
                key={tab}
                $selected={productType === tab} 
                onClick={() => { 
                  if (productType !== tab) {
                    setSelectedProductType(tab)
                  }
                }}
              >
                {tab}
              </TabButton>
            )
          })
        }
        </div>
      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px'
      }}>

      {
        productType === 'Unprovisioned Codes'
        ?
        <Button 
          style={{ display: 'inline-flex' }} 
          onClick={onProvisonNewCodes} 
          $colored={true}
        >
          {'Provision New Codes'}
        </Button>
        :
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={labelStyle}>Provison Group:</div>
            <SelectDropdown 
              id='select-provision-group'
              options={provisonGroupOptions}
              onChange={(val) => setProvisionGroupNumber(val)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={labelStyle}>Code:</div>
            <TextInput
              value={searchValue}
              onChange={(val) => setSearchValue(val)}
              placeholder='Search Specific Code'
            />
            {/* <div style={{ marginLeft: '40px', marginRight: '5px' }}>Download Codes:</div>
            <Button 
              style={{ width: '250px' }} 
              $colored={true} 
              $disabled={!canDownload} 
              onClick={() => downloadQuestCodes(filters.productType, filters.provisionGroupNumber)} 
            >
              {'Download'}
            </Button> */}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={labelStyle}>Download Codes:</div>
            <Button 
              style={{ width: '250px' }} 
              $colored={true} 
              $disabled={!canDownload} 
              onClick={() => {
                if (canDownload) {
                  downloadQuestCodes(filters.productType, filters.provisionGroupNumber)
                }
              }} 
            >
              {'Download'}
            </Button>
            <div style={{ marginLeft: '10px', fontSize: '11px', fontStyle: 'italic', color: '#565656' }}>*Provision Group selection required</div>
          </div>
        </>
      }
      </div>

    </div>
  )
}