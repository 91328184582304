import React from "react";
import ShipmentHeader from "../ShipmentHeader";
import ShippedItemsBlock from '../ShippedItemsBlock/ShippedItemsBlock'
import FormField from "../../../CommonComponents/FormField";

export default ({
  formData,
  shipment,
  isReadOnly
}) => {
  const {
    itemImage,
    itemName,
    itemType,
    quantity,
  } = shipment;

  const items = itemName ? [{
    name: itemName,
    image: itemImage,
    type: itemType,
    quantity
  }] : [];

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px'
      }}>
        <FormField
          fieldId='receivedName'
          type='text'
          label='Full Name'
          disabled={isReadOnly}
          formData={formData}
        />
        <FormField
          fieldId='receivedDate'
          type='date'
          label='Date Recieved'
          disabled={isReadOnly}
          formData={formData}
        />
      </div>
      <div style={{
        border: '1px solid #dedede',
        borderRadius: '5px',
        marginTop: '20px',
      }}>
        <ShipmentHeader shipment={shipment} />
        <div style={{ padding: '20px' }}>
          <ShippedItemsBlock items={items} missingItemsFormData={formData} missingItemsFormDataIsReadOnly={isReadOnly} />
        </div>
      </div>
    </>
  )
}