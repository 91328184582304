import React, { useState, useEffect } from 'react';
import LoadingIcon from "../../../CommonComponents/Animations/LoadingIcon";
import useFetch from '../../../hooks/useFetch';
import styled from 'styled-components';

const Item = styled.div`
  background: ${props => props.$selected ? '#F3FBFF' : '#ffffff'};
  color: ${props => props.$selected ? '#0280c6' : '#080808'};
  padding: 10px;
  border-top: 1px solid #c4c4c4;
  /* color: #080808; */
  font-size: 16px;
  word-wrap: break-word;
  cursor: pointer;
  &:last-child {
    border-bottom: 1px solid #c4c4c4;
  }
`;

export default ({
  selectedAssessmentName,
  onAssessementClicked,
  setHasTags
}) => {
  const fetch = useFetch();

  const [isLoading, setIsLoading] = useState(true)
  const [assessmentNames, setAssessmentNames] = useState([]);

  const fetchAssessmentNames = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`assessment/list`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAssessmentNames([])
      } else {
        setAssessmentNames(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAssessmentNames([])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAssessmentNames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const doesAssessmentHaveTags = (name) => {
    const assessmentObj = assessmentNames.find(({ name: assessmentName}) => assessmentName === name);
    if (assessmentObj) {
      const { questions = [] } = assessmentObj;

      let hasTag = false;
      questions.forEach(({ tags }) => {
        if (tags && Array.isArray(tags) && tags.length > 0) {
          hasTag = true;
        }
      });

      return hasTag;
    } else {
      return true;
    }
  }

  return (

    <div style={{
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      // borderRight: '1px solid #c4c4c4',
      padding: '0px'
    }}>

        <div style={{
          color: '#565656',
          fontSize: '24px',
          fontWeight: 'bold',
          padding: '20px'
        }}>
          {'Assessment'}
        </div>

        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          {
            isLoading ?
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <LoadingIcon />
              </div>
              :
              assessmentNames
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ name = '' }) => {
                return (
                  <Item 
                    key={name} 
                    onClick={() => {
                      setHasTags(doesAssessmentHaveTags(name))
                      onAssessementClicked(name)
                    }}
                    $selected={name === selectedAssessmentName}
                  >
                    {name}
                  </Item>
                )
              })
          }
        </div>

    </div>
  )
}