import React from 'react';
import { FaStar } from 'react-icons/fa';

export default ({
  stars: starsRaw = 0,
}) => {
  const stars = Math.round(starsRaw)

  const EmptyStar = () => <FaStar style={{ color: '#b1b5c4' }} />
  const FullStar = () => <FaStar style={{ color: '#fa9201' }} />
  const HalfStar = () => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <div style={{
          position: 'absolute',
          overflow: 'hidden',
          width: '50%'
        }}>
          <FullStar />
        </div>
        <EmptyStar />
      </div>
    )
  }

  const numFullStars = Math.floor(stars / 2);
  const halfStarIndex = stars % 2 ? numFullStars : -1;

  const starsArray = [];
  for (let i = 0; i < 5; i++) {
    starsArray.push(
      <span key={`star${i}`} style={{ marginRight: '4px'}}>
        {
           (i < numFullStars) ?
           <FullStar /> 
           : 
           (halfStarIndex === i) ?
           <HalfStar />
           :
           <EmptyStar />
        }
      </span>
    );
  }

  return <div>{starsArray}</div>
}
