import React, { useState } from "react";
import useFetch from '../hooks/useFetch';

export default () => {

  const fetch = useFetch();

  const [isBusy, setIsBusy] = useState(false);

  const onLogOutClicked = async () => {
    if (!isBusy) {
      setIsBusy(true)

      try {
        const response = await fetch(
          `auth/logout`, 
          { 
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        )

        const jsonData = await response.json() || {};

        const { status = '' } = jsonData;

        setIsBusy(false);

        if (status) {
          window.location.reload()
        }
      } catch (e) {
        setIsBusy(false);
      }
    }
  }

  return (
    <div
      style={{
        position: 'sticky',
        top: '0',
        zIndex: '999',
      }}
    >
      <div style={{ 
        display: 'flex',
        backgroundColor: '#F6F7F8',
        borderBottom: '1px solid #c4c4c4',
        color: '#080808', 
        padding: '0px 20px', 
        height: '39px',
        alignItems: 'center'
      }}>
        <div style={{ flex: 1 }}>&nbsp;</div>
        <div 
          style={{
            color: '#0280c6', 
            textDecoration: 'underline', 
            fontWeight: 'normal', 
            cursor: 'pointer',
            fontSize: '14px'
          }}
          onClick={onLogOutClicked}
        >
          Log Out
        </div>
      </div>
    </div>
  )
}