import React, { useState } from 'react';
import FullScreenImage from './FullScreenImage';
import { Button, Section } from '../../CommonComponents/BaseComponents';
import { useGlobalData } from '../../GlobalData';
import { SectionLabel } from '../../CommonComponents/BaseComponents';
import { CiExport } from 'react-icons/ci';

export default ({
  programData = {}
}) => {
  const { addImageFormUrl, images = [] } = programData;
  // Test images
  // const images = [
  //   {imageUrl: 'https://picsum.photos/300/200'},
  //   {imageUrl: 'https://picsum.photos/300/300'},
  //   {imageUrl: 'https://picsum.photos/400/500'},
  //   {imageUrl: 'https://picsum.photos/400/300'},
  //   {imageUrl: 'https://picsum.photos/400/300'},
  // ]

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const [focusedImageUrl, setFocusedImageUrl] = useState()

  const onFocusImageHighlight = (imageUrl) => {
    setFocusedImageUrl(imageUrl)
  }

  return (
    <Section>
      {
        focusedImageUrl &&
        <FullScreenImage imageUrl={focusedImageUrl} onClose={() => setFocusedImageUrl(undefined)} />
      }
      <div style={{
        display: 'flex',
        flexDirection: isMobileView ? 'column' : 'row',
        marginBottom: '20px',
      }}>
        <SectionLabel>Photo Gallery</SectionLabel>
        {
          addImageFormUrl &&
          <div style={{ 
            display: 'flex', 
            flex: 1,
            alignItems: 'center', 
            justifyContent: isMobileView ? 'flex-start' : 'flex-end', 
            marginTop: isMobileView ? '10px' : '0px' 
          }}>
            <Button onClick={() => window.open(addImageFormUrl, '_blank')}>
              <CiExport style={{ marginRight: '5px'}} />
              <span style={{ fontSize: '13px', marginTop: '2px' }}>Add Image</span>
            </Button>
          </div>
        }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: isMobileView ? 'column' : 'row',
        flexWrap: 'wrap',
        gap: '10px 10px',
        justifyContent: 'left',
        // alignItems: 'center',
        // marginLeft: isMobileView ? '0px' : '-10px'
      }}>
        {
          images.length > 0 ?
          images
          .filter((image, index) => Boolean(index < 6))
          .map(({ imageUrl }, index) => {
            return (
              <div 
                key={`${imageUrl}-${index}`} 
                style={{ 
                  // width: isMobileView ? 'auto' : 'calc(33% - 10px)', 
                  // minWidth: isMobileView ? 'auto' :'120px', 
                  // flex: isMobileView ? '1' : 'none',
                  // display: 'flex',
                  maxWidth: isMobileView ? '100%' : 'calc(66% - 10px)',
                  cursor: 'pointer',
                  flexGrow: 1,
                  height: '220px',
                }}
                onClick={() => onFocusImageHighlight(imageUrl)}
              >
                <img 
                  style={{ 
                    width: '100%', 
                    maxHeight: '100%',
                    minWidth: '100%',
                    objectFit: 'cover',
                    borderRadius: '5px' 
                  }} 
                  src={imageUrl} alt='programImageHighlight' />
              </div>
            )
          })
          :
          <div style={{
            color: '#808080',
            fontSize: '14px',
            textAlign: 'center',
            width: '100%',
            // marginLeft: '20px',
          }}>
            No photos available.
          </div>
        }
      </div>
    </Section>
  )
}
