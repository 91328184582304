import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

export default ({
  title = '',
  defaultOpen,
  children,
  style = {},
  expandedStyle = {},
  headerExpands
}) => {

  const [isExpanded, setIsExpanded] = useState(defaultOpen || false)

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div
        onClick={() => headerExpands && setIsExpanded(!isExpanded)}
        style={{
          display: 'flex',
          padding: '20px',
          cursor: headerExpands ? 'pointer' : 'default',
          ...style,
        }}
      >
        <div style={{ flex: 1 }}>{title}</div>
        <div 
          style={{
            cursor: 'pointer'
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {
            isExpanded 
            ? <FaChevronUp style={{ color: '#44ACE1' }} />
            : <FaChevronDown style={{ color: '#b1b5c4' }} />
          }
        </div>
      </div>
      <div style={{ 
        display: isExpanded ? 'block' : 'none',
        padding: '0px 20px',
        ...expandedStyle,
      }}>
          {children}
      </div>
    </div>
  )
}