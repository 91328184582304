import React from 'react';
import { Main, MainContent, Section, SectionLabel } from './CommonComponents/BaseComponents';
import { useGlobalData } from './GlobalData';
import Footer from './CommonComponents/Footer';

export default () => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;
  return (
    <Main $isMobileView={isMobileView}>
      <MainContent $isMobileView={isMobileView}>
        <Section 
          style={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            height: '400px',
            marginBottom: '10px'
          }}
        >
          <SectionLabel>Invalid Page URL</SectionLabel>
          <div style={{ fontStyle: 'italic', marginTop: '10px' }}>Please check the url above to confirm you're at the correct location</div>
        </Section>
        <Footer />
      </MainContent>
    </Main>
  )
}