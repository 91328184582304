import { useState, useEffect } from 'react';

export default (domRef) => {

  const [isVisible, setVisible] = useState(false);
  
  // Below state makes it so that this only fades in one time
  const [visibilityOccured, setVisibilityOccured] = useState(false)

  useEffect(() => {
    let observerRefValue;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const isEntryVisible = entry.isIntersecting;
        setVisible(isEntryVisible)
        if (isEntryVisible) {
          setVisibilityOccured(true)
        }
      });
    });

    if (domRef.current) {
      observer.observe(domRef.current);
      observerRefValue = domRef.current;
    }

    observer.observe(domRef.current);
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    };
  }, [domRef]);

  return { isVisible, visibilityOccured };
};