import React from 'react';
import styled from 'styled-components';

const BlueCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  cursor: ${props => props.disabled ? 'default' : ' pointer'};
  appearance: none;
  background-color: white;
  border: ${props => props.disabled ? '1px solid #b4b4b4' : '1px solid #0280c6'};
  border-radius: 5px;
  transition: background-color 0.2s, border-color 0.2s;
  position: relative;

  &:checked {
    background-color: ${props => props.disabled ? '#b4b4b4' : '#0280c6'};
    border-color: ${props => props.disabled ? '#b4b4b4' : '#0280c6'};
  }

  &:checked::after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: relative;
    top: 1px;
    left: 6px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 3px 3px rgba(0, 0, 255, 0.2);
  }
`;

export default ({
  label = '',
  value,
  onChange,
  disabled,
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <BlueCheckbox
      value={value}
      checked={value}
      onChange={onChange}
      disabled={disabled}
    />
    <div style={{ marginLeft: '3px', cursor: 'pointer'}} onClick={onChange}>{label}</div>
  </div>
);
