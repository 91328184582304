import React from "react";
import { useGlobalData } from '../GlobalData';
import { FaTimes } from 'react-icons/fa';
import { IconButtonWrapper } from '../CommonComponents/BaseComponents'

export default (props) => {
  const {
    onClose,
    title,
    children,
    isFullScreen: isFullScreenRaw,
    isDark,
    isAutoHeight,
    noContentPadding,
    footer
  } = props;

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  // Always do a fullscreen modal when in mobile view
  const isFullScreen = isMobileView || isFullScreenRaw;

  const iconWrapperStyle = title ? 
    {
      position: 'absolute',
      right: '15px',
      top: '10px'
    } : 
    { 
      float: 'right', 
      marginTop: '2px', 
      marginBottom: '2px', 
      marginRight: '2px' 
    }

  return (
    <div style={{ 
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      background: '#b8b8b8b8',
      zIndex: '100000'
    }}>
      <div style={{ 
        padding: isFullScreen ? '5px 5px' : '60px 5px',
        height: isFullScreen ? 'calc(100vh - 10px)' : 'calc(100vh - 120px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div style={{
          background: isDark ? '#161616' : '#ffffff',
          width: isFullScreen ? '100%' : '75%',
          height: isAutoHeight ? 'auto' : '100%',
          maxWidth: isFullScreen ? 'none' : '950px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <div style={{
            position: 'relative'
          }}>
            {
              Boolean(title) &&
              <div style={{
                background: '#f8f8f8',
                fontWeight: 'bold',
                fontSize: '18px',
                color: '#2c2c2c',
                padding: '15px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottom:  '1px solid #dedede'
              }}>
                {title}
              </div>
            }
            <IconButtonWrapper
              $isDark={isDark} 
              style={iconWrapperStyle}
              onClick={onClose}
            >
              <FaTimes style={{ fontSize: '22px' }}  />
            </IconButtonWrapper>
          </div>
          <div style={{ flex: 1, overflow: 'auto', padding: noContentPadding ? '0px' : '20px' }}>
          {children}
          </div>
          { 
            Boolean(footer) && 
            <div 
              style={{
                borderTop: '1px solid #dedede',
              }}
            >
              {footer}
            </div>
          }
        </div>
      </div>
    </div>
  )
}