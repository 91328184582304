import React, { useState, useEffect } from "react";
import AnswerOptionEditor from "./AnswerOptionEditor";
import { Button } from '../../../../CommonComponents/BaseComponents'

export default ({
  answerOptions = [],
  isReadOnly,
  type,
  onChange
}) => {

  let highestAnswerOptionId = 0;
  answerOptions.forEach(({ id }) => {
    const idNum = Number(id);
    if (idNum > highestAnswerOptionId) {
      highestAnswerOptionId = idNum;
    }
  });

  const startingNewAnswerOptionsId = highestAnswerOptionId + 1;

  const [newAnswerOptionId, setNewAnswerOptionId] = useState(startingNewAnswerOptionsId)

  const initAnswerOptions = answerOptions.map(qObj => ({ ...qObj }));

  const [editedAnswerOptions, setEditedAnswerOptions] = useState(initAnswerOptions)

  useEffect(() => {
    onChange(editedAnswerOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedAnswerOptions])

  const addNewAnswerOption = () => {
    const newEmptyAnswerOptionObj = {
      id: newAnswerOptionId,
      answerOption: '',
    }
    const updatedAnswerOptions= [...editedAnswerOptions];
    updatedAnswerOptions.push(newEmptyAnswerOptionObj)
    
    setEditedAnswerOptions(updatedAnswerOptions)

    setNewAnswerOptionId(newAnswerOptionId + 1)
  }

  const onAnswerOptionChanged = (answerOption = {}) => {
    const aOIndex = editedAnswerOptions.findIndex(({ id }) => id === answerOption.id)

    if (aOIndex > -1) {
      const aOObj = editedAnswerOptions[aOIndex];

      const updatedAOObj = { ...aOObj, ...answerOption};

      const updatedAnswerOptions = [...editedAnswerOptions];
      updatedAnswerOptions[aOIndex] = updatedAOObj;

      setEditedAnswerOptions(updatedAnswerOptions)
    }
  }

  const onAnswerOptionDeleted = (question = {}) => {
    const aOIndex = editedAnswerOptions.findIndex(({ id }) => id === question.id)
    if (aOIndex > -1) {
      const updatedAnswerOptions = [...editedAnswerOptions];
      updatedAnswerOptions.splice(aOIndex, 1)
      setEditedAnswerOptions(updatedAnswerOptions)
    }
  }

  return (
    <div style={{
      padding: '20px',
      background: '#ffffff',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    }}>
      <div style={{ 
        display: 'flex',
        marginBottom: '10px'
      }}>
        <div 
          style={{
            flex: 1,
            color: '#565656', 
            fontSize: '14px', 
            fontWeight: 'bold',
            marginBottom: '10px'
          }}
        >
          {'Answer Options'}
        </div>
        <Button
          style={{ width: '250px' }}
          $colored={true}
          $disabled={isReadOnly}
          onClick={() => {
            if (!isReadOnly) {
              addNewAnswerOption()
            }
          }}
        >
          {'Add New Answer Option'}
        </Button>
      </div>
      {
        answerOptions.map(answerOption => {
          return (
            <AnswerOptionEditor
              key={answerOption.id}
              answerOption={answerOption}
              isReadOnly={isReadOnly}
              type={type}
              onChange={onAnswerOptionChanged}
              onDelete={onAnswerOptionDeleted}
            />
          )
        })
      }
    </div>
  )
}