import React, { useState, useEffect } from 'react';
import Stars from './Stars';
import SelectDropdown from '../SelectDropdown'

export default ({
  id,
  width,
  onStarsChanged
}) => {
  const [stars, setStars] = useState(0)

  useEffect(() => {
    if (onStarsChanged) {
      onStarsChanged(stars)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stars])

  const starOptions = [0,10,8,6,4,2].map(val => ({ 
    value: val, 
    text: (
      <div>
        {
          val === 0 ?
          <div>{'All Ratings'}</div>
          :
          <div style={{ position: 'relative', top: '2px' }}><Stars stars={val} /></div>
        }
      </div> 
    ),
    hasBorderBottom: val === 0 ? true : false
  }))

  const onStarOptionsChange = (starOption) => {
    setStars(starOption)
  }

  return (
    <SelectDropdown 
      id={id}
      width={width}
      options={starOptions}
      onChange={onStarOptionsChange}
    />
  )
}
