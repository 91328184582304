import React, { useState } from "react";
import useFetch from '../hooks/useFetch';

export default () => {

  const fetch = useFetch();

  const [isBusy, setIsBusy] = useState(false);

  const onLogOutClicked = async () => {
    if (!isBusy) {
      setIsBusy(true)

      try {
        const response = await fetch(
          `auth/logout`, 
          { 
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        )

        const jsonData = await response.json() || {};

        const { status = '' } = jsonData;

        setIsBusy(false);

        if (status) {
          window.location.reload()
        }
      } catch (e) {
        setIsBusy(false);
      }
    }
  }

  return (
    <div
      style={{
        color: '#0280c6',
        textDecoration: 'underline',
        fontWeight: 'normal',
        cursor: 'pointer',
        fontSize: '14px'
      }}
      onClick={onLogOutClicked}
    >
      Log Out
    </div>
  )
}