import React, { useState } from "react";
import styled from 'styled-components';
import { Button } from '../../../../CommonComponents/BaseComponents'
import { 
  FaPlus, 
  FaEllipsisH,
  FaExclamationTriangle 
} from "react-icons/fa";
import processQuestionsForCategories from "./processQuestionsForCategories";
import MenuPopup from "../../../../CommonComponents/MenuPopup";
import NewCategoryPopup from "./NewCategoryPopup";

const Item = styled.div`
  position: relative;
  background: ${props => props.$selected ? '#F3FBFF' : '#ffffff'};
  color: ${props => props.$selected ? '#0280c6' : '#080808'};
  padding: 10px;
  /* padding-right: 22px; */
  padding-left: 30px;
  padding-right: 30px;
  border-top: 1px solid #c4c4c4;
  /* color: #080808; */
  font-size: 16px;
  word-wrap: break-word;
  cursor: pointer;
  /* &:last-child {
    border-bottom: 1px solid #c4c4c4;
  } */
  &.dropItemOver {
    border-top: 1px solid #0280c6;
  }
`;

const BottomItem = styled.div`
  border-top: 1px solid #c4c4c4;
  padding: 10px;
  &.dropItemOver {
    border-top: 1px solid #0280c6;
  }
`;

const CategoryContainer = styled.div`
  /* margin-top: 10px; */
  /* margin-bottom: 30px; */
  position: relative;
  &:first-child {
    margin-top: 0px;
  }
`;

const CategoryHeader = styled.div`
  position: relative;
  padding-top: 3px;
  /* border-top: 1px solid transparent; */
  border-top: 1px solid #c4c4c4;
  background: #f8f9fc;
  padding: 10px 30px 10px 20px;
  /* padding-top: 0px; */
  color: #565656;
  font-weight: bold;
  cursor: ${props => props.$draggable ? 'pointer' : 'default'};
  &.dropItemOver {
    border-top: 1px solid #0280c6;
  }
`;

const CategoryFooter = styled.div`
  position: absolute;
  bottom: 25px;
  width: 100%;
  height: 10px;
  background: #f8f9fc;
  border-bottom: 1px solid #c4c4c4;
`;

let currentDraggingElement;

export default ({
  questions = [],
  modifiedObjects = {},
  selectedQuestion,
  onQuestionSelected,
  onIntroSelected,
  addNewQuestion,
  addNewCategory,
  updateCategory,
  isReadOnly,
  moveAndSortQuestions
}) => {

  const onDragStart = (e, item) => {
    currentDraggingElement = e.target;
    const { id } = item;
    e.dataTransfer.setData("text", id);
    e.dataTransfer.effectAllowed = "move";
  }

  const onDragCategoryStart = (e, item) => {
    currentDraggingElement = e.target;
    const { id } = item;
    e.dataTransfer.setData("text", `cat:${id}`);
    e.dataTransfer.effectAllowed = "move";
  }

  const onDragOver = (e) => {
    e.preventDefault();
  }

  const onDragEnter = (e) => {
    const element = e.target;
    if (currentDraggingElement !== element) {
      element.classList.add('dropItemOver');
    }
  }

  const onDragLeave = (e) => {
    const element = e.target;
    element.classList.remove('dropItemOver');
  }

  const removeDropItemOverCss = () => {
    const containerEl = document.getElementsByClassName('eligibleDropItem');
    for(let i = 0; i < containerEl.length; i++) {
      const draggableItemEl = containerEl[i];
      draggableItemEl.classList.remove('dropItemOver');
    }
    currentDraggingElement = undefined;
  }

  const onDrop = (e, itemDropTarget, categoryParam, isTargetCategoryHeader) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("text");
    if (data.indexOf('cat:') > -1) {
      const catData = data.split('cat:')[1];
      return onDropCategory(catData, itemDropTarget, categoryParam, isTargetCategoryHeader)
    } else {
      return onDropItem(data, itemDropTarget, categoryParam, isTargetCategoryHeader)
    }
  }

  const onDropCategory = (data, itemDropTarget, categoryParam, isTargetCategoryHeader) => {
    const catObj = questionsWithCategories.find(({ id }) => id === data);
    if (catObj) {
      let toIndex;
      if (Number.isInteger(itemDropTarget)) {
        toIndex = itemDropTarget;
      } else if (isTargetCategoryHeader) {
        const idFirstChild = itemDropTarget.children[0].id;
        toIndex = questions.findIndex(({ id }) => id === idFirstChild);
      } else if (categoryParam) {
        // If we drag and drop a category into another category, we must ignore the potential target (which may be mid category, and always use the first item in the cat)
        const targetCatObj = questionsWithCategories.find(({ id }) => id === categoryParam) || {};
        const idFirstChild = targetCatObj.children[0].id;
        toIndex = questions.findIndex(({ id }) => id === idFirstChild);
      } else {
        toIndex = questions.findIndex(({ id }) => id === itemDropTarget.id);
      }

      const { category, children } = catObj;

      // Use the first child item to base our form index from
      const fromIndex = questions.findIndex(({ id }) => id === children[0].id);
      moveAndSortQuestions(fromIndex, toIndex, category, children.length);
    }
    removeDropItemOverCss();
  }

  const onDropItem = (data, itemDropTarget, categoryParam, isTargetCategoryHeader) => {
    const itemToChange = questions.find(({ id }) => id === Number(data));
    if (itemToChange) {
      const fromIndex = questions.findIndex(({ id }) => id === itemToChange.id)

      let toIndex;
      let category = categoryParam;
      if (Number.isInteger(itemDropTarget)) {
        toIndex = itemDropTarget;
      } else if (isTargetCategoryHeader) {
        const idFirstChild = itemDropTarget.children[0].id;
        toIndex = questions.findIndex(({ id }) => id === idFirstChild);
        category = '';
      } else {
        toIndex = questions.findIndex(({ id }) => id === itemDropTarget.id);
      }

      // Can't use this condition below because index is the same when dropping one up directly outside a category
      // if (fromIndex !== toIndex) {
      moveAndSortQuestions(fromIndex, toIndex, category);
      // }
    }
    removeDropItemOverCss();
  }

  const { categories: existingCategories, questionsWithCategories } = processQuestionsForCategories(questions);

  // console.log(questionsWithCategories)

  const renderQuestionItem = (questionObj, topIndex, category, isLastCatItem) => {
    const { id, question } = questionObj;

    let isPrevCatOrFirst = false;
    let isLastItemIndex = -1;
    if (category) {
      // logic if we know we are inside a category

      if (isLastCatItem) {
        isLastItemIndex = questions.findIndex(({ id: qId }) => qId === id) + 1;
      }
    } else {
      // logic if we know we are a standalone question

      const nextItemObj = questionsWithCategories[topIndex + 1];

      if (nextItemObj && nextItemObj.isCategory) {
        isLastItemIndex = questions.findIndex(({ id: qId }) => qId === id) + 1;
      }
      
      if (topIndex === (questionsWithCategories.length - 1)) {
        isLastItemIndex = questions.length;
      }

      // Find if previous item is a category or we are the first standalone item
      const prevItemObj = questionsWithCategories[topIndex - 1];
      if ((prevItemObj && prevItemObj.isCategory) || topIndex === 0) {
        isPrevCatOrFirst = true;
      }
    }

    return (
      <React.Fragment key={id}>
        {
          isPrevCatOrFirst &&
          <div style={{ padding: '3px 0px 0px 0px' }}>{''}</div>
        }
        <Item
          className="eligibleDropItem"
          onClick={() => {
            onQuestionSelected(questionObj)
          }}
          $selected={id === (selectedQuestion && selectedQuestion.id)}
          draggable={!isReadOnly}
          onDragStart={e => onDragStart(e, questionObj)}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={e => onDrop(e, questionObj, category)}

        >
          {question || '...'}
          {
            modifiedObjects[id] &&
            <FaExclamationTriangle style={{ position: 'absolute', right: '10px', color: '#EAC60C' }} />
          }
        </Item>
        {
          (isLastItemIndex > -1) &&
          <>
          <BottomItem
            className="eligibleDropItem"
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={e => onDrop(e, isLastItemIndex, category)}
          >
            &nbsp;
          </BottomItem>
          {
            category &&
            <CategoryFooter>
             &nbsp;
            </CategoryFooter>
          }
          </>
        }
      </React.Fragment>
    )
  }

  const [createNewCategoryOpen, setCreateNewCategoryOpen] = useState()

  const [addNewMenuOpen, setAddNewMenuOpen] = useState(false)

  const [catMenuOpen, setCatMenuOpen] = useState()

  const createNewCategory = ({ initCategoryName, isNew, category }) => {
    if (isNew) {
      addNewCategory(category)
    } else {
      updateCategory(initCategoryName, category);
    }
    
    setCreateNewCategoryOpen(undefined)
  }

  const onNewMenuSelection = (selectedOption) => {
    if (selectedOption === 'Question') {
      addNewQuestion()
    } else {
      setCreateNewCategoryOpen('new')
    }
  }

  const onCatMenuSelection = (selectedOption, category) => {
    if (selectedOption === 'Rename') {
      setCreateNewCategoryOpen(category)
    }
  }

  return (
    <div style={{
      width: '400px',
      display: 'flex',
      flexDirection: 'column',
      borderRight: '1px solid #c4c4c4',
      padding: '0px',
      overflow: 'auto'
    }}>
      {
        Boolean(createNewCategoryOpen) &&
        <NewCategoryPopup
          initCategoryName={createNewCategoryOpen}
          createNewCategory={createNewCategory}
          existingCategories={existingCategories}
          onClose={() => setCreateNewCategoryOpen(undefined)}
        />
      }
      {
        (Object.keys(modifiedObjects).length > 0) &&
        <div style={{ display: 'flex', alignItems: 'center', position: 'fixed', top: '20px', right: '100px' }}>
          <FaExclamationTriangle style={{ color: '#EAC60C', fontSize: '14px' }} />
          <div style={{ fontSize: '12px', fontStyle: 'italic', fontWeight: 'normal', color: '#7e7e7e' }}>
            {'* Indicates you have unsaved modification to the marked item.'}
          </div>
        </div>
      }
      <div style={{
        color: '#565656',
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '20px'
      }}>
        {'General'}
      </div>
      <div>
        <Item
          style={{
            borderBottom: '1px solid #c4c4c4'
          }}
          onClick={() => {
            onIntroSelected()
          }}
          $selected={!selectedQuestion}
        >
          {'Introduction Section'}
          {
            modifiedObjects['intro'] &&
            <FaExclamationTriangle style={{ position: 'absolute', right: '10px', color: '#EAC60C' }} />
          }
        </Item>
      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative'
        }}>
          <div style={{
            color: '#565656',
            fontSize: '18px',
            fontWeight: 'bold',
            padding: '20px'
          }}>
            {'Questions'}
          </div>
          {
            (modifiedObjects['all'] || modifiedObjects['sort']) &&
            <FaExclamationTriangle style={{ position: 'relative', left: '-10px', color: '#EAC60C' }} />
          }
          <div style={{ position: 'absolute', right: '5px' }}>
            <Button
              style={{ width: '2px', height: '14px', borderRadius: '50%', position: 'relative' }}
              $colored={true}
              $disabled={isReadOnly}
              onClick={(e) => {
                if (!isReadOnly) {
                  e.stopPropagation();
                  setAddNewMenuOpen(true)
                  // addNewQuestion()
                }
              }}
            >
              <FaPlus style={{ fontSize: '12px', position: 'absolute', top: '5px', left: '5px' }} />
            </Button>
            <MenuPopup 
              open={addNewMenuOpen} 
              top='-2px'
              left='-105px'
              options={['Question', 'Category']}
              onClose={() => setAddNewMenuOpen(false)} 
              onSelection={onNewMenuSelection} 
            />
          </div>
        </div>
        <div style={{ 
          color: '#7e7e7e', 
          fontSize: '13px', 
          fontStyle: 'italic', 
          marginLeft: '3px', 
          position: 'relative', 
          top: '-8px',
          padding: '0px 20px'
        }}>
          {'*Drag and drop questions and categories below to change the sort order.'}
        </div>
      </div>
      <div className="questionsContainerElement">
        {
          questionsWithCategories.map((questionObj, index) => {
            const {
              id,
              isCategory,
              category,
              children
            } = questionObj;
            if (isCategory) {
              return (
                <React.Fragment key={id}>
                <CategoryContainer>
                  <CategoryHeader
                    className="eligibleDropItem"
                    $draggable={!isReadOnly}
                    draggable={!isReadOnly}
                    onDragStart={e => onDragCategoryStart(e, questionObj)}
                    onDragOver={onDragOver}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={e => onDrop(e, questionObj, category, true)}
                  >
                    {category}
                    <div style={{ position: 'absolute', top: '0px', right: '0px' }}>
                      <FaEllipsisH
                        style={{ color: '#7e7e7e', padding: '10px' }}
                        onClick={(e) => { e.stopPropagation(); setCatMenuOpen(category) }}
                      />
                      <MenuPopup 
                        open={catMenuOpen === category}
                        top='10px'
                        left='-100px'
                        options={['Rename']}
                        onClose={() => setCatMenuOpen(undefined)} 
                        onSelection={(val) => onCatMenuSelection(val, category)}
                      />
                    </div>
                  </CategoryHeader>
                  <div className="questionsContainerElement">
                  {
                    children.map((catQuestionObj, catIndex) => {
                      return renderQuestionItem(catQuestionObj, index, category, (catIndex === children.length - 1))
                    })
                  }
                  </div>
                </CategoryContainer>
                </React.Fragment>
              )
            } else {
              return renderQuestionItem(questionObj, index)
            }
          })
        }
      </div>
    </div>
  )
}