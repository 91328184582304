import React from "react";
import Modal from "../../../CommonComponents/Modal";
import { Button } from '../../../CommonComponents/BaseComponents'

export default ({
  onClose,
  onSubmit
}) => {

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      padding: '10px'
    }}>
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      <Button onClick={onSubmit} $colored={true}>
        {'Continue'}
      </Button>
    </div>
  )

  return (
    <Modal title={'Confirm Publish'} isAutoHeight={true} onClose={onClose} footer={footer}>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div>
          {'Once you publish this draft, any previous version of this assessment will no longer be active and only this version will be seen be users.'}
        </div>
        <div style={{ marginTop: '40px'}}>
          {'Do you wish to proceed?'}
        </div>
      </div>
    </Modal>
  )
}