import React from 'react';
import { useGlobalData } from '../GlobalData';
import TextInput from "./TextInput";
import TextAreaInput from './TextAreaInput';
import DateInput from "./DateInput";
import CurrencyInput from './CurrencyInput';
import SelectDropdown from './SelectDropdown';
import Checkbox from './Checkbox';

export default ({
  style = {},
  fieldStyle = {},
  labelStyle = {},
  fieldId,
  type,
  label,
  width,
  formData,
  onKeyDown,
  disabled,
  options,
  placeholder
}) => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const Field = (type === 'text' || type === 'number' || type === 'password') ? TextInput
   : type === 'textarea' ? TextAreaInput 
   : type === 'date' ? DateInput 
   : type === 'currency' ? CurrencyInput 
   : type === 'select' ? SelectDropdown 
   : type === 'checkbox' ? Checkbox 
   : React.Fragment;

  const {
    values, 
    setValues,
    missingValues = {}
  } = formData;

  // Checkbox needs to handle onChange different then all the others
  const onChange = type === 'checkbox' 
    ? () => setValues({ ...values, [fieldId]: !values[fieldId] })
    : (val) => setValues({ ...values, [fieldId]: val });

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: isMobileView ? 'column' : 'row',
      rowGap: isMobileView ? '15px' : '0px',
      columnGap: isMobileView ? '0px' : '15px',
      ...style
    }}>
      <div style={{ flex: 1, width: isMobileView ? '100%' : 'auto', display: 'flex', flexDirection: 'column' }}>
        <div style={{
          marginBottom: '5px',
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#565656',
        }}>
          <span style={labelStyle}>{label}</span>
          { missingValues[fieldId] && 
            <span style={{ 
              marginLeft: '5px',
              color: '#ef0c0c',
              fontWeight: 'normal',
              fontSize: '11px',
              fontStyle: 'italic'
            }}>
              *Required
            </span>
          }
        </div>
        <Field 
          type={type}
          style={fieldStyle}
          value={values[fieldId]} 
          onChange={onChange}
          onKeyDown={onKeyDown}
          isInvalid={missingValues[fieldId]}
          disabled={disabled}
          options={options}
          width={width}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}