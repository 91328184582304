import React, { useEffect } from 'react';
import styled from 'styled-components';

const Option = styled.div`
  font-size: 14px;
  font-weight: normal;
  padding: 8px 20px;
  cursor: pointer;
  background: ${props => props.$selected ? "#44ACE1" : "#ffffff"};
  color: ${props => props.$selected ? "#ffffff" : "#080808"};
  text-align: left;
  border-bottom: ${props => props.$hasBorderBottom ? '1px solid #dedede' : 'none'};
  &:hover {
    background: #44ACE1!important;
    color: #ffffff!important;
  }
`;

export default ({
  open,
  options = [],
  top,
  left,
  onClose,
  onSelection
}) => {

  useEffect(() => {
    const offClickEvent = () => {
      onClose()
    }
    document.addEventListener('click', offClickEvent)

    return () => {
      document.removeEventListener('click', offClickEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return open ? (
    <div style={{
      position: 'absolute',
      background: '#ffffff',
      width: '100px',
      top: top || '-10px',
      left: left || '25px',
      overflow: 'auto',
      border: '1px solid #c4c4c4',
      borderRadius: '5px',
      zIndex: '1',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div
        style={{
          border: '1px solid #c4c4c4',
          borderRadius: '5px',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        {
          options.map(item => {
            return (
              <Option 
                key={item}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelection(item);
                  onClose();
                }}
              >
                {item}
              </Option>
            )
          })
        }
      </div>
    </div>
  ) : <></>
}