import React, { useState } from 'react';
import ShippedItemsBlock from './ShippedItemsBlock/ShippedItemsBlock';
import RecipientDetailsBlock from './RecipientDetailsBlock';
// import { Button } from '../../CommonComponents/BaseComponents'
// import { FaBox } from 'react-icons/fa';
import ShipmentHeader from './ShipmentHeader';
import ManageShipmentModal from './ManageShipmentModal/ManageShipmentModal'

export default ({
  shipment,
  updateShipmentData
}) => {

  const {
    itemImage,
    itemName,
    itemProgramName,
    quantity,
    contactName,
    contactEmail,
    contactPhone,
    address,
    city,
    state,
    zip,
  } = shipment;

  const items = itemName ? [{
    name: itemName,
    image: itemImage,
    programName: itemProgramName,
    quantity
  }] : [];

  const recipientDetails = {
    name: contactName,
    email: contactEmail,
    phone: contactPhone,
    address,
    city,
    state,
    zip,
  }

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onConfirmShipmentClick = () => {
    setShowConfirmModal(true)
  }

  return (
    <>
    {
      showConfirmModal &&
      <ManageShipmentModal
        shipment={shipment}
        onClose={() => setShowConfirmModal(false)} 
        updateShipmentData={updateShipmentData}
      />
    }
    <div style={{
      border: '1px solid #dedede',
      borderRadius: '5px',
      marginTop: '20px',
    }}>
      <ShipmentHeader shipment={shipment} onConfirmShipmentClick={onConfirmShipmentClick} />
      <div style={{
        padding: '20px',
        display: 'flex'
      }}>
        <div style={{ flex: 1 }}>
          <ShippedItemsBlock items={items} />
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
          <RecipientDetailsBlock recipientDetails={recipientDetails} />
          {/* <div 
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: '#565656',
              fontSize: '14px',
              rowGap: '7px',
              padding: '15px',
              marginTop: '15px',
              border: '1px solid #dedede',
              borderRadius: '5px',
              backgroundColor: '#f8f8f8',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaBox style={{ fontSize: '18px', marginRight: '8px'}} />
              <div style={{ color: '#080808', fontSize: '16px'}}>{'Recieved your shipment? Let us know!'}</div>
            </div>
            <div>{'If you have received the package, click the button below and let us know if we got everything correct.'}</div>
            <Button onClick={onConfirmShipmentClick} $colored={true} style={{ height: '25px', fontSize: '13px', marginTop: '5px' }}>
              {'CONFIRM SHIPMENT CONTENTS'}
            </Button>
          </div> */}
        </div>
      </div>
    </div>
    </>
  )
}