import React, { useState, useRef } from 'react';

export default ({
  text,
  children
}) => {

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const topOffset = 10;

  const toolTipRef = useRef();
  const triggerElementRef = useRef();

  const handleMouseEnter = () => {
    if (toolTipRef.current && triggerElementRef.current) {
      const triggerElement = triggerElementRef.current.getBoundingClientRect();
      const tooltipElement = toolTipRef.current.getBoundingClientRect();

      const tooltipTop = (tooltipElement.height * -1) - topOffset;
      const tooltipLeft = (triggerElement.width / 2) -  (tooltipElement.width / 2);
  
      setTooltipPosition({ top: tooltipTop, left: tooltipLeft });

      setShowTooltip(true);
    }
  }

  const handleMouseLeave = () => {
    setShowTooltip(false);
  }

  return(
    <div 
      style={{
        position: 'relative'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      { text &&
        <div 
          ref={toolTipRef}
          style={{
            visibility: showTooltip ? 'visible' : 'hidden',
            position: 'absolute',
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            background: '#ffffff',
            padding: '10px',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            border: '1px solid #dedede',
            borderRadius: '5px',
            // width: '180px',
          }
        }>
          {text}
          <div style={{
            position: 'absolute',
            width: '0px',
            height: '0px',
            borderTop: '10px solid #ffffff',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            bottom: '-10px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}>
            &nbsp;
          </div>
        </div>
      }
      <div ref={triggerElementRef}>
        {children}
      </div>
    </div>
  )
}