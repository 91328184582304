import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "./DateInput.css"

export default forwardRef(({
  value,
  onChange,
  disabled,
  isInvalid,
  style: styleRaw
}, ref) => {
  const momentValue = moment(value);
  const initValue = (momentValue && momentValue.isValid()) ? momentValue.toDate() : '';

  const [selectedDate, setSelectedDate] = useState(initValue);

  const onSelectedChange = (date) => setSelectedDate(date);

  useImperativeHandle(ref, () => {
    return {
      clearInput() {
        setSelectedDate('')
      }
    };
  }, []);

  useEffect(() => {
    // Format selectedDate into desired format for coming back out of this component - YYYY-MM-DD
    const selectedMomentValue = moment(selectedDate);
    const formattedValue = (selectedMomentValue && selectedMomentValue.isValid()) ? selectedMomentValue.format('YYYY-MM-DD') : '';
    onChange(formattedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const wrapperStyle = styleRaw || {};
  return (
    <div style={wrapperStyle} className={disabled ? 'date-input-disabled' : isInvalid ? 'date-input-invalid' : ''}>
      <DatePicker
        selected={selectedDate}
        onChange={onSelectedChange}
        disabled={disabled}
      />
    </div>
  )
})