import React from 'react';
import StarsSelector from '../../../CommonComponents/Stars/StarsSelector';

export default ({
  questionId,
  answers,
  setAnswers,
  starLabels,
  totalStars,
}) => {

  const answerObj =  answers[questionId] || {};
  const {
    answerText = ''
  } = answerObj;

  const onUpdateAnswer = (val) => {
    const updateObj = { answerText: val }
    setAnswers({
      ...answers,
      [questionId]: updateObj
    })
  }

  const onStarsChanged = (stars) => {
    if (stars > 0) {
      onUpdateAnswer(stars)
    }
  }

  const initStarValue = (answerText && Number(answerText)) || 0;

  return (
    <StarsSelector
      initStarValue={initStarValue}
      starLabels={starLabels}
      totalStars={totalStars}
      selectedStarColor='#d2275a'
      onStarsChanged={onStarsChanged}
    />
  )
}